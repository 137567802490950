// https://support.google.com/analytics/answer/1136920?hl=en#zippy=%2Cin-this-article
// https://www.axllent.org/docs/track-outbound-links-with-gtag-js
// Use this to test: trackLink('/practice/about', 'calculator_submission', {'event_category': 'success'});
var SWP = {
  trackClick: function(el, eventName, opts) {
    // is target set and _(self|parent|top)
    var targetNewWindow = (el.target && !el.target.match(/^_(self|parent|top)$/i)) ? el.target : false;

    if(targetNewWindow) {
       // If the target is opening in a new window then just track the click
       this.trackEvent(eventName, opts);
       return true;
     } else {
       this.trackLink(el.href, eventName, opts);
       return false;
     }
  },
  trackLink: function(url, eventName, opts) {
    var hasRun = false;

    var eventCallback = function() {
      if(hasRun) { return; }
      hasRun = true;
      window.location.href = url;
    }

    if(typeof gtag == "undefined") {
      eventCallback()
      return true;
    }

    var eventOpts = {'event_callback': eventCallback}

    // Doing merge this way for full browser support back to old browsers
    for (var attrname in opts) { eventOpts[attrname] = opts[attrname]; }

    // Run callback if analytics takes more than 1 second
    setTimeout(eventCallback, 1000);

    gtag('event', eventName, eventOpts);
    // console.log("Event Link: " + eventName + " " + JSON.stringify(eventOpts))

    window.uetq = window.uetq || [];
    window.uetq.push("event", eventName, {});

    return true;
  },
  trackEvent: function(eventName, opts) {
    if(typeof gtag == "undefined") { return }
    gtag('event', eventName, opts);
    window.uetq = window.uetq || [];
    window.uetq.push("event", eventName, {});
    //console.log("Event: " + eventName + " " + JSON.stringify(opts))
  }
}

export default SWP;