// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

$('#navbar-toggler').on('click', function() {
	$('#navigation-mobile').toggleClass('show');
});

$('#close-navbar').on('click', function() {
	$('#navigation-mobile').toggleClass('show');
});

import SWP from 'application/practice/google-link-tracker'
window.SWP = SWP